define("frontend/transforms/moment", ["exports", "ember-data/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  Ember.Date = Ember.Date || {};

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      var type = _typeof(serialized);

      if (type === "string") {
        var offset = serialized.indexOf('+');

        if (offset !== -1 && serialized.length - 3 === offset) {
          (false && !(false) && Ember.deprecate("The ECMA2015 Spec for ISO 8601 dates does not allow for shorthand timezone offsets such as +00.\n          Ember Data's normalization of date's allowing for this shorthand has been deprecated, please update your API to return\n          UTC dates formatted with \xB1hh:mm timezone offsets or implement a custom UTC transform.", false, {
            id: 'ds.attr.date.normalize-utc',
            until: '3.0.0'
          }));
          return moment("".concat(serialized, ":00")); // this is a phantom specific bug fix in which +0000 is not supported
        } else if (offset !== -1 && serialized.length - 5 === offset) {
          offset += 3;
          return moment(serialized.slice(0, offset) + ':' + serialized.slice(offset));
        }

        return moment(serialized);
      } else if (type === "number") {
        return moment(serialized);
      } else if (serialized === null || serialized === undefined) {
        // if the value is null return null
        // if the value is not present in the data return undefined
        return serialized;
      } else {
        return null;
      }
    },
    serialize: function serialize(date) {
      if (date instanceof Date && !isNaN(date)) {
        return date.toISOString();
      } else if (date instanceof moment && !isNaN(date)) {
        return date.toISOString();
      } else {
        return null;
      }
    }
  });

  _exports.default = _default;
});